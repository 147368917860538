@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.email,
.businessName,
.address,
.phoneNumber,
.minimumOrder,
.numberOfProducts,
.offerSamples,
.website {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.name,
.location {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.nameRoot {
  width: 100%;
}

.locationRoot {
  width: calc(50% - 9px);
}


.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  @media (--viewportLarge) {
    margin-top: 48px;
  }
}

.bottomWrapperText,
.bottomWrapperHighlightedText {
  composes: marketplaceModalBottomWrapperText from global;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.bottomWrapperHighlightedText {
  text-decoration: underline;
  margin-bottom: 12px;
}

.bottomWrapperHighlightedText .termsText {
  font-size: 18px;
  color: var(--matterColor);
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.submitButton {
  margin-top: 24px;
}
